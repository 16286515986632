/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.social-feed-container').socialfeed({
          // FACEBOOK
          facebook:{
            accounts: ['@landingbase'],
            limit: 2,
            access_token: '1102179866464417|34UVNk3lt5IC6UFiP4G8XYhptcg' // APP_ID|APP_SECRET
          },

          // INSTAGRAM
          //instagram:{
          //  accounts: ['@pavur_outdoors'],  //Array: Specify a list of accounts from which to pull posts
          //  limit: 4,                                   //Integer: max number of posts to load
          //  client_id: '6a4fabcf777a4e8eb51ade5ed6e904bd'       //String: Instagram client id
          //},

          // GENERAL SETTINGS
          length:200,                                     //Integer: For posts with text longer than this length, show an ellipsis.
          show_media:true,                                //Boolean: if false, doesn't display any post images
          media_min_width: 300,                           //Integer: Only get posts with images larger than this value
          update_period: 5000,                            //Integer: Number of seconds before social-feed will attempt to load new posts.
          template: "/wp-content/themes/lb/social-template.html",                         //String: Filename used to get the post template.
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
